import { graphql } from 'gatsby';
import React from 'react';
import { BlogCard } from '../components';
import Layout from '../components/layout';
import { toSlug } from '../utils/slugify';

const Blog = ({ data: { allPrismicBlogpost } }) => {
  return (
    <Layout
      seo={{
        title: 'Aktuális',
        description:
          'Sikerélményt viszünk a fiatalok életébe szociális helyzettől függetlenül.'
      }}
    >
      <div className="bg-bgfirst">
        <div className="container pt-32 pb-20 text-center">
          <h2 className="uppercase mt-10 mb-10 text-4xl md:text-5xl">
            AKTUALITÁSOK
          </h2>
          <div className="flex flex-wrap justify-center">
            {allPrismicBlogpost.edges.map(({ node }) => {
              const { data, last_publication_date: date } = node;
              const { images, title, lead } = data;
              const slug = toSlug(title.text);
              return (
                <BlogCard
                  to={`/${slug}`}
                  key={title.text}
                  src={images.url}
                  lead={lead.text}
                  date={date}
                  title={title.text}
                />
              );
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Blog;

export const query = graphql`
  query Blog {
    allPrismicBlogpost(sort: { order: DESC, fields: last_publication_date }) {
      edges {
        node {
          last_publication_date(formatString: "YYYY. MMMM DD.", locale: "HU")
          url
          data {
            images {
              url
            }
            text {
              html
            }
            title {
              text
            }
            lead {
              text
            }
          }
        }
      }
    }
  }
`;
